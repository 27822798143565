<template>
    <div class="login-logo">
        <component :is="logoComponentName"></component>
    </div>
</template>

<script>
export default {
    name: 'LoginLogo',
    components: {
        AdminLogo: () => import('@/modules/views/logos/AdminLogo')
    },
    props: {
        logoComponentName: {
            type: String,
            default: 'AdminLogo'
        }
    }
};
</script>

<style lang="scss">
.login-logo {
    border-radius: 0;
    height: 7.44rem;
    margin: auto;
    width: 14.87rem;
}
</style>
