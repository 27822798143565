<template>
    <div id="internal-login-base">
        <div class="photo-section">
            <img class="internal-login-base__photo" src="@/assets/svg/login-left-photo.jpg" />
        </div>
        <div class="login-section">
            <login-logo :logo-component-name="logoComponentName" />
            <div class="router-wrapper">
                <router-view />
            </div>
        </div>
    </div>
</template>

<script>
import LoginLogo from '../common/LoginLogo';
import logger from '@/lib/logger';

export default {
    name: 'InternalLoginBase',
    components: {
        LoginLogo
    },
    computed: {
        logoComponentName() {
            // here should be the logic that determines which logo should be displayed
            return 'AdminLogo';
        }
    },
    created() {
        logger.debug(`${this.$options.name} root App.vue created.`);
    }
};
</script>

<style lang="scss">
#internal-login-base {
    color: $blue-charcoal;
    display: flex;
    height: 100vh;
    left: 0;
    position: absolute;
    text-align: center;
    top: 0;
    width: 100%;
    // damn you to hell bootstrap
    .compat-container {
        @media (min-width: 768px) {
            width: 750px;
        }
        @media (min-width: 992px) {
            width: 970px;
        }
        @media (min-width: 1200px) {
            width: 1170px;
        }
    }

    .internal-login-base {
        &__title {
            font-size: 1.9rem;
            line-height: 2.3rem;
            margin-bottom: 2.5rem;
        }
    }

    .photo-section {
        height: 100%;
        text-align: left;
        width: 50%;
        .internal-login-base__photo {
            border-radius: 0;
            bottom: 0%;
            height: 100%;
            width: auto;
        }
    }

    .login-section {
        margin: auto;
        width: 50%;
        .router-wrapper {
            margin-top: 35px;
            min-height: 450px;
        }
    }
}
</style>
